<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="日期">
        <el-date-picker
          v-model="searchForm.startTime"
          type="date"
          value-format="yyyy-MM-dd"
          size="mini"
          :clearable="false"
        ></el-date-picker
        >--
        <el-date-picker
          v-model="searchForm.endTime"
          type="date"
          value-format="yyyy-MM-dd"
          size="mini"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>

      <el-form-item label="失败次数" width="120px">
        <el-input v-model="searchForm.count" size="mini" clearable></el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="search" type="primary" size="mini">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="exportTable" size="mini"
          >失败退回并导出</el-button
        >
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column prop="merchantName" label="商户名称"></el-table-column>

      <el-table-column prop="orderNo" label="商户单号"></el-table-column>

      <el-table-column prop="cardNo" label="用户卡号"></el-table-column>
      <el-table-column prop="ifsc" label="IFSC"></el-table-column>
      <el-table-column prop="amount" label="交易金额">
        <template slot-scope="scope">
          <span>{{ scope.row.amount / 100 }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="count" label="代付次数"></el-table-column>
      <el-table-column prop="channellist" label="渠道列表"></el-table-column>
      <el-table-column prop="remark" label="备注"></el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="reject(scope.row)"
            >失败退回</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
  </section>
</template>

<script>
import errorOrderApi from "../../api/orderManage/lendErrorOrder";

import api from "../../api/orderManage/payManyTimesErrorOrder";

import DataUtil from "../../utils/config/dataUtil";

export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      dataTotal: 0,
      loading: false,
      tableData: [],
      searchForm: {
        count: "",
        startTime: this.dateFrom(),
        endTime: this.date4Query(new Date()),
      },
      multipleSelection: [],
    };
  },
  methods: {
    dateTransform(date) {
      return DataUtil.dateTransform(date);
    },
    date4Query(date) {
      return DataUtil.dateTransformYMD(date);
    },
    dateFrom() {
      let dt = new Date();
      dt.setDate(dt.getDate() - 30);
      return DataUtil.dateTransformYMD(dt);
    },
    search() {
      this.pageIndex = 1;
      this.bindGrid();
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    exportTable() {
      this.$confirm("确认失败退回并导出?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.searchForm.count == "" || this.searchForm.count == null) {
            this.$message({
              message: "请填写失败次数",
              type: "error",
            });
            return;
          }
          if (this.searchForm.startTime == null) {
            this.searchForm.startTime = "";
          }
          if (this.searchForm.endTime == null) {
            this.searchForm.endTime = "";
          }
          let _data = {};
          let content = Object.assign({}, this.searchForm);
          _data.content = content;
          content.count = parseInt(content.count);
          api.exportPayManyTimesErrorOrder(_data).then((res) => {
            const blob = new Blob([res]);
            const downloadURL = (
              window.URL || window.webkitURL
            ).createObjectURL(blob);
            let a = document.createElement("a");
            a.download = "代付多次异常订单.txt";
            // 创建二进制对象
            a.href = downloadURL;
            // 模拟点击
            a.click();
            //释放资源并删除创建的a标签
            URL.revokeObjectURL(downloadURL); // a.href
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.bindGrid();
          });
        })
        ["catch"](() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    reject(row) {
      this.$confirm("确认失败处理？, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let _data = {};
          let content = {};
          content.id = row.id;
          _data.content = content;
          errorOrderApi.rejectOrder(_data).then((res) => {
            this.logining = false;
            if (res.code == "0000") {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.bindGrid();
            } else {
              this.$message.error(res.message);
            }
          });
        })
        ["catch"](() => {
          this.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    bindGrid() {
      this.loading = true;
      if (this.searchForm.startTime == null) {
        this.searchForm.startTime = "";
      }
      if (this.searchForm.endTime == null) {
        this.searchForm.endTime = "";
      }
      let _data = {};
      let content = Object.assign({}, this.searchForm);
      content.pageNo = this.pageIndex;
      content.pageSize = this.pageSize;
      _data.startTime = this.searchForm.startTime;
      _data.endTime = this.searchForm.endTime;
      _data.content = content;
      api.getPayManyTimesErrorOrder(_data).then((res) => {
        this.logining = false;
        if (res.code == "0000") {
          this.loading = false;
          this.tableData = res.data.rows;
          this.dataTotal = res.data.total;
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;
      this.bindGrid();
    },
  },
  mounted: function () {
    this.bindGrid();
  },
  components: {},
};
</script>

<style lang="scss">
@import "../../assets/css/base";
.el-form-item {
  margin-bottom: 10px;
}
</style>
