import request from "@/utils/http/requset";

function getPayManyTimesErrorOrder(data) {
    return request({
        url: "/manage/withdraw/searchErrorOrderChannel",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data
    });
}

function exportPayManyTimesErrorOrder(data) {
    return request({
        url: "/manage/withdraw/exportAndRejectErrorOrderChannel",
        method: "post",
        ContentType: "application/json;charset=UTF-8",
        data: data,
        responseType: 'blob'
    });
}
export default {
    getPayManyTimesErrorOrder,
    exportPayManyTimesErrorOrder
};
