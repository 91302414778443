import request from "@/utils/http/requset";

function getLengErrorOrderList(data) {
  return request({
    url: "/manage/withdraw/searchErrorOrder",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

function callbackOrder(data) {
  return request({
    url: "/manage/withdraw/callback",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

function pullBackOrder(data) {
  return request({
    url: "/manage/withdraw/pullback",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}

function rejectOrder(data) {
  return request({
    url: "/manage/withdraw/reject",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
export default {
  getLengErrorOrderList,
  callbackOrder,
  pullBackOrder,
  rejectOrder
};
